import React, {useState} from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import compose from 'lodash/flowRight'
import * as enums from '../../../lib/enums'
import {Button} from '../../../components/Button'
import {Container, ColContainer, TPL_THIRDS,CardContainer} from '../../../components/Container'
import {DateRangeInput} from '../../../components/DateRangeInput'
import {H1, H2} from '../../../components/Typography'
import {Helmet} from 'react-helmet'
import {LoadingError} from '../../../components/LoadingFork'
import {SelectInput} from '../../../components/SelectInput'
import {css} from 'glamor'
import {stringify} from 'qs'
import {useQuery} from "@apollo/client"
import {withAppConfig} from '../../../components/AppConfigContext'
import {withInstallation} from '../../../components/InstallationContext'
import {
    isAfter,
    addDays,
    addMonths,
    addYears,
    setMonth,
    format,

    startOfDay,
    endOfDay,

    startOfMonth,
    endOfMonth,

    startOfYear,
    endOfYear
} from 'date-fns'
import {DateTime} from "luxon";

const QUERY = gql`
	query getExportPreview (
		$installationId: ID!
		$rangeStart: String
		$rangeEnd: String
		$employee: ID
		$status: BookingStatusEnum
	) {
		allUsers(filter: { installationId: $installationId }) {
			id
			name
		}

		_allBookingsMeta(
			filter: {
				installation: $installationId
				status: $status
				createdBy: $employee
				startDay_gte: $rangeStart
				startDay_lte: $rangeEnd
			}
		) {
			count
		}
	}
`;

function Bookings({installation, appConfig}) {
    const TODAY = DateTime.now().setZone(installation.timeZoneName).toJSDate();
    const YESTERDAY = addDays(TODAY, -1)
    const LAST_MONTH = addMonths(TODAY, -1)
    const NEXT_YEAR = addYears(TODAY, 1)
    const LAST_YEAR = addYears(TODAY, -1)

    const DATE_RANGE_OPTIONS = {
        Today: [startOfDay(TODAY), endOfDay(TODAY)],
        Yesterday: [startOfDay(YESTERDAY), endOfDay(YESTERDAY)],
        'This month': [startOfMonth(TODAY), endOfMonth(TODAY)],
        'Last month': [startOfMonth(LAST_MONTH), endOfMonth(LAST_MONTH)],
        YTD: [startOfYear(TODAY), endOfYear(TODAY)],
        'FY YTD':
          isAfter(TODAY, startOfMonth(setMonth(TODAY, 6)))
            ? [startOfMonth(setMonth(TODAY, 6)), endOfMonth(setMonth(NEXT_YEAR, 5))]
            : [startOfMonth(setMonth(LAST_YEAR, 6)), endOfMonth(setMonth(TODAY, 5))],
    };

    const [dates, setDates] = useState(DATE_RANGE_OPTIONS.Today)
    const [status, setStatus] = useState('')
    const [employee, setEmployee] = useState('')
    const [startDate, endDate] = dates
    const {data = {}, error} = useQuery(QUERY, {
        variables: {
            installationId: installation.id,
            employee: employee || undefined,
            status: status || undefined,
            rangeStart: DateTime.fromJSDate(startDate || DateTime.now().toJSDate(), {zone: installation.timeZoneName}).toISODate(),
            rangeEnd: DateTime.fromJSDate(endDate || DateTime.now().toISODate(), {zone: installation.timeZoneName}).toISODate(),
        }
    })


    const employeeOptions = [
        {
            label: 'All employees',
            value: '',
        },
        ...(data.allUsers || []).map(({id, name}) => {
            return {
                label: name,
                value: id,
            }
        })
    ]

    let dataCount = 0
    if (data._allBookingsMeta) {
        dataCount = data._allBookingsMeta.count
    }

    const hasData = dataCount > 0
    const datesReady = startDate && endDate
    const downloadReady = datesReady && hasData

    let downloadURL = '';
    if (downloadReady) {
        const query = {
            start: DateTime.fromJSDate(startDate || DateTime.now().toJSDate(), {zone: installation.timeZoneName}).toISODate(),
            end: DateTime.fromJSDate(endDate || DateTime.now().toJSDate(), {zone: installation.timeZoneName}).toISODate(),
        }
        if (status) query.status = status;
        if (employee) query.employee = employee;
        downloadURL = `${appConfig.APPLICATION_URL}/bookings/${installation.id}/download?${stringify(query)}`
    }

    // TODO: .toDate() is for moment, but, it isn't happening now?
    return <div>

        <CardContainer>
            <Helmet>
                <title>Bookings</title>
            </Helmet>
            <Container size="large" spaceAbove="small" spaceBelow="none">
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    })}
                >
                    <H1>Bookings Data Export</H1>
                </div>
            </Container>
            <Container size="large" spaceAbove="small" spaceBelow="medium">
                <H2>Filters</H2>
                {error && <LoadingError error={error}/>}
                <ColContainer columnTemplate={TPL_THIRDS} spaceAbove="medium">
                    <DateRangeInput float
                                    label="Date Range"
                                    options={DATE_RANGE_OPTIONS}
                                    onChange={(dates) => setDates(dates.map(x => x.toDate ? x.toDate() : x))}
                                    value={dates.map(x => moment(x))}
                    />

                    <SelectInput
                        label="Booking Status"
                        autoComplete="off"
                        options={enums.BOOKING_STATUS_SEARCH_OPTIONS}
                        onChange={(v) => setStatus(v.target.value)}
                        value={status}
                    />

                    <SelectInput
                        label="Employee"
                        autoComplete="off"
                        options={employeeOptions}
                        onChange={(v) => setEmployee(v.target.value)}
                        value={employee}
                    />
                </ColContainer>

                <div>{hasData ? `${dataCount} bookings found` : 'No bookings found'}</div>
                <Button spaceAbove="small" href={downloadURL} disabled={!downloadReady}>
                    Download
                </Button>
            </Container>
        </CardContainer>

    </div>
}

export default compose(
    withAppConfig,
    withInstallation,
)(Bookings);
