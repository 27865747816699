import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactApexChart from "react-apexcharts";
import { withInstallation } from '../../components/InstallationContext';
import gql from 'graphql-tag'
import { useMutation, useQuery } from "@apollo/client";
import drivoApollo, { DRIVO_URL } from "../../lib/drivoApollo";
import Typography from "@material-ui/core/Typography";
import { CheckboxInput } from '../../components/CheckboxInput'
import { Input } from '../../components/Input';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'
import {AdminOnly, CanSeeDailyReport} from '../../components/AdminOnly';
import DailyReportTable from "./DailyReportTable";
import { Button } from "../../components/Button";
import authManager from '../../lib/authManager';
import {BookingSummary} from "../../components/BookingSummary";
import {DateTime} from "luxon";
import {DateInput} from "../../components/DateInput";

export const useStyles = makeStyles(() => ({
    card: {
        marginBottom: '20px',
        borderRadius: '14px',
        background: '#fff',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
        overflow: 'visible',
    },
    cardText: {
        fontSize: '18px',
        fontWeight: 500,
        opacity: 0.72,
        paddingBottom: '4px'

    },
}))

function isToday(date, timezone){
    if (!date) return false;

    return date.hasSame?.(DateTime.now().setZone(timezone), "day")
}

const CardContainer = ({ children }) => {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}

function Dashboard({ installation }) {
    const [updateCarsCount] = useMutation(gql`
        mutation Capacity (
            $installationId: ID!
            $capacity:Int!,
            $plateEnabled:Boolean!,
            $limitCapacity:Int!
            $userId: String
        ) {
            updateCapacity(
                installationId:$installationId,
                capacity:$capacity,
                plateEnabled:$plateEnabled,
                limitCapacity:$limitCapacity
                userId: $userId
            ) {
                id
                capacity
                limitToSanctionedPlatesEnabled
                limitToSanctionedPlateCapacity
            }
        }
    `, { client: drivoApollo })

    const [carCount, setCarCount] = useState(0)
    const [currentCapacityDebounceTimeout, setCurrentCapacityDebounceTimeout] = useState(null)
    const [limit, setLimit] = useState(0)
    const [bookingsUntilFull, setBookingsUntilFull] = useState(1000)
    const [limitCheck, setLimitCheck] = useState(true)
    const [date, setDate] = React.useState(DateTime.now().setZone(installation.timeZoneName).toISODate())
    const [reportStartDate, setReportStartDate] = useState('');
    const [reportEndDate, setReportEndDate] = useState('');

    const { data: capacity, refetch: capacityRefetch } = useQuery(gql`
        query Capacity($installationId:ID!)
        {
            getCapacity(installationId:$installationId){
                id
                capacity
                limitToSanctionedPlatesEnabled
                limitToSanctionedPlateCapacity
                bookingsUntilFull
            }
        }
    `, {
        client: drivoApollo,
        variables: { installationId: installation.id },
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        if (capacity && capacity.getCapacity) {
            setCarCount(capacity.getCapacity.capacity ?? 0);
            setLimitCheck(capacity.getCapacity.limitToSanctionedPlatesEnabled ? true : false)
            setLimit(capacity.getCapacity.limitToSanctionedPlateCapacity)
            setBookingsUntilFull(capacity.getCapacity.bookingsUntilFull)
        }
    }, [capacity])

    const { data, error } = useQuery(
        gql`
            query Analytics($installationId: ID!) {
                getAnalytics(installationId:$installationId) {
                    revenueOverTime {
                        date
                        amount
                    }
                    rateCardUsage {
                        name
                        count
                    }
                    buzzOutsOverTime{
                        date
                        amount
                    }
                    previousBuzzOutsOverTime{
                        date
                        amount
                    }
                    buzzInsOverTime{
                        date
                        amount
                    }
                    entriesOverTime{
                        date
                        amount
                    }
                }
            }`,
        {
            client: drivoApollo,
            variables: { installationId: installation.id },
            fetchPolicy: "no-cache",
        }
    );
    const revenueOverTime = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.revenueOverTime
        ) {
            return data.getAnalytics.revenueOverTime;
        } else {
            return 0;
        }
    })();

    const { data: dataByDate, refetch: dataByDateRefetch } = useQuery(
        gql`
            query Analytics($installationId: ID!, $date:String!) {
                getDataByDate(installationId:$installationId, date:$date){
                    revenueByDate{
                        count
                        amount
                    }
                    revenueLastMonth{
                        count
                        amount
                    }
                    revenueLastWeek{
                        count
                        amount
                    }
                    buzzOutByDate{
                        count
                        amount
                    }
                    buzzInByDate{
                        count
                        amount
                    }
                    refundByDate{
                        count
                        amount
                    }
                    getForeCast{
                        count
                        amount
                    }
                    chargebacks {
                        count
                        amount
                    }

                }

            }
        `,
        {
            client: drivoApollo,
            variables: {
                installationId: installation.id,
                date: date
            },
            fetchPolicy: "no-cache",
        }
    )
    //'2021-06-23'

    const { data: dailyRateCard, refetch: dailyRateCardRefetch } = useQuery(
        gql`
            query Analytics($installationId: ID!, $date:String!) {
                rateCardSplitByDate(installationId:$installationId, date:$date){
                    name
                    count
                }
            }
        `,
        {
            client: drivoApollo,
            variables: {
                installationId: installation.id,
                date: date
            },
            fetchPolicy: "no-cache",
        }
    )
    let dailyRateCardLabels = []
    let dailyRateCardSeries = []
    for (let i = 0; i < dailyRateCard?.rateCardSplitByDate.length; i++) {
        dailyRateCardSeries.push(dailyRateCard?.rateCardSplitByDate[i].count)
        dailyRateCardLabels.push(dailyRateCard?.rateCardSplitByDate[i].name)
    }

    let dailyRateCardGraphData = {
        series: dailyRateCardSeries,
        options: {
            plotOptions: {
                pie: {
                    customScale: 1.35,
                }
            },
            grid: {
                padding: {
                    top: 30,
                    right: 0,
                    bottom: 0,
                    left: 10
                },
            },
            chart: {
                width: 500,
                type: 'pie',
            },
            labels: dailyRateCardLabels,
            title: {
                text: "Daily Rate Card",
                align: 'left'
            },
            responsive: [{
                breakpoint: 420,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    }


    const dailyAnalytics = dataByDate?.getDataByDate
    let lineData = []
    let categories = []

    for (let i = 0; i < revenueOverTime.length; i++) {
        lineData.push(revenueOverTime[i].amount)
        categories.push(revenueOverTime[i].date.substr(0, 10))
    }

    let revenuePerDayGraphData = {
        series: [{
            name: "Revenue Per Day",
            data: lineData
        }],
        options: {
            chart: {
                height: 300,
                type: 'area',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            title: {
                text: "Revenue",
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: 'date',
                categories: categories,
            },
            yaxis: [{
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2)
                    }
                }
            }],
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
        },


    };

    //for rate card usage
    const rateCardUse = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.rateCardUsage
        ) {
            return data?.getAnalytics?.rateCardUsage;
        } else {
            return 0;
        }
    })();

    let labels = []
    let series = []
    for (let i = 0; i < rateCardUse.length; i++) {
        series.push(rateCardUse[i].count)
        labels.push(rateCardUse[i].name)
    }

    let rateChartUsageGraphData = {
        series: series,
        options: {
            plotOptions: {
                pie: {
                    customScale: 1.35,
                }
            },
            grid: {
                padding: {
                    top: 30,
                    right: 0,
                    bottom: 0,
                    left: 10
                },
            },
            chart: {
                width: 500,
                type: 'pie',
            },
            labels: labels,
            title: {
                text: "Rate Card Usage",
                align: 'left'
            },
            responsive: [{
                breakpoint: 420,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        }
    }


    //for car entries
    const carEntries = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.entriesOverTime
        ) {
            return data.getAnalytics.entriesOverTime;
        } else {
            return 0;
        }
    })();
    lineData = []
    categories = []
    for (let i = 0; i < carEntries.length; i++) {
        lineData.push(carEntries[i].amount)
        categories.push(carEntries[i].date.substr(0, 10))
    }

    let carEntriesGraphData = {

        series: [{
            name: 'entries',
            data: lineData
        },
        ],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            title: {
                text: "Entries",
                align: 'left'
            },
            xaxis: {
                type: 'date',
                categories: categories
            },
            yaxis: [{
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2)
                    }
                }
            }],
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
        },


    };


    //for buzz outs
    const buzzOuts = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.buzzOutsOverTime
        ) {
            return data.getAnalytics.buzzOutsOverTime;
        } else {
            return 0;
        }
    })();

    //for buzz outs
    const previousBuzzOuts = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.previousBuzzOutsOverTime
        ) {
            return data.getAnalytics.previousBuzzOutsOverTime;
        } else {
            return 0;
        }
    })();

    const buzzIns = (() => {
        if (
            !error &&
            data &&
            data.getAnalytics &&
            data.getAnalytics.buzzInsOverTime
        ) {
            return data.getAnalytics.buzzInsOverTime;
        } else {
            return 0;
        }
    })();
    const lineDataBuzzIn = []
    const lineDataBuzzOut = []
    const previousLineDataBuzzOut = []
    categories = []
    for (let i = 0; i < buzzOuts.length; i++) {
        lineDataBuzzOut.push(buzzOuts[i].amount)
        categories.push(buzzOuts[i].date.substr(0, 10))
    }
    for (let i = 0; i < previousBuzzOuts.length; i++) {
        previousLineDataBuzzOut.push(previousBuzzOuts[i].amount)
    }
    for (let i = 0; i < buzzIns.length; i++) {
        lineDataBuzzIn.push(buzzIns[i].amount)
    }

    let buzzOutsGraphData = {

        series: [{
            name: 'buzz-outs',
            data: lineDataBuzzOut
        }, {
            name: 'previous-buzz-outs',
            data: previousLineDataBuzzOut
        }, {
            name: 'buzz-ins',
            data: lineDataBuzzIn
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            title: {
                text: "Buzz Outs",
                align: 'left'
            },
            xaxis: {
                type: 'date',
                categories: categories,
            },
            yaxis: [{
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2)
                    }
                }
            }],
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
        },
    };

    const handleLimit = (e, para) => {
        let checkLimit = limitCheck
        let limitValue = limit
        if (para === 'limitCheck') {
            setLimitCheck(!limitCheck)
            checkLimit = !limitCheck
        }
        if (para === 'limit') {
            setLimit(e)
            limitValue = e
        }

        if (currentCapacityDebounceTimeout != null) {
            clearTimeout(currentCapacityDebounceTimeout);
        }
        let currentTimeout = setTimeout(async () => {

            if (isNaN(parseInt(carCount, 10))) {
                return console.error("[DRIVO] Capacity is not a number, bailing");
            }

            if (limitValue === "") {
                return console.error("[DRIVO] Limit value is empty, bailing");
            }

            if (isNaN(parseInt(limitValue), 10)) {
                return console.error("[DRIVO] Limit value is not a number, bailing");
            }

            if (checkLimit === null || checkLimit === undefined) {
                return console.error("[DRIVO] checkLimit is not true or false, bailing");
            }

            await updateCarsCount({
                variables: {
                    installationId: installation.id,
                    capacity: carCount,
                    plateEnabled: checkLimit,
                    limitCapacity: parseInt(limitValue, 10),
                    userId: authManager?.userId ?? null
                }
            })
            capacityRefetch();

        }, 2000);
        setCurrentCapacityDebounceTimeout(currentTimeout);

    }

    const [focused, setFocused] = React.useState()


    const classes = useStyles()
    return (
        <div style={{ height: '850px', overflowY: 'auto', padding: '5px 5px 0px 5px' }}>
            <CardContainer>
                <ReactApexChart
                    options={revenuePerDayGraphData.options}
                    series={revenuePerDayGraphData.series}
                    type="line"
                    height={350}
                />
            </CardContainer>

            <CardContainer>
                <ReactApexChart
                    options={carEntriesGraphData.options}
                    series={carEntriesGraphData.series}
                    type="line"
                    height={350}
                />
            </CardContainer>

            <Grid container spacing={1}>
                <Grid item xs={6}>

                    <Card className={classes.card} style={{ height: '370px' }}>
                        <CardContent>
                            <ReactApexChart
                                options={rateChartUsageGraphData.options}
                                series={rateChartUsageGraphData.series}
                                type="pie"
                                width={500}
                            />
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={6}>
                    <Card
                        className={classes.card}
                        style={{
                            display: 'flex',
                            height: '370px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CardContent>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontSize: '20px',
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            opacity: 0.72
                                        }}
                                    >
                                        BOOKINGS UNTIL FULL
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            fontWeight: 600,
                                            color: '#3127B8',
                                            fontSize: '6rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {(limit == null || limit === 0) ? "-" :
                                            bookingsUntilFull > 0 ? bookingsUntilFull : "FULL"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={8} style={{ marginLeft: '15px' }}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 500,
                                            opacity: 0.72
                                        }}
                                    >
                                        Limit to Sanctioned Plates
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <CheckboxInput checked={limitCheck} onChange={e => handleLimit(e, 'limitCheck')} />
                                </Grid>


                                <Grid item xs={5} style={{ marginLeft: '15px' }}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 500,
                                            opacity: 0.72
                                        }}
                                    >
                                        Limit
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ marginLeft: '25px' }}>
                                    <Input
                                        type={"number"}
                                        style={{ width: '50%', margin: 'auto' }}
                                        value={limit}
                                        onValueChange={e => handleLimit(e, 'limit')}
                                        disabled={!limitCheck}
                                    />
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={12}>

                    <CardContainer>
                        <ReactApexChart
                            options={buzzOutsGraphData.options}
                            series={buzzOutsGraphData.series}
                            type="line"
                            height={350}
                        />

                        <div style={{ marginLeft: 50 }}>
                            <Button
                                spaceAbove="small"
                                target={"_blank"}
                                href={`${DRIVO_URL}/buzzout-report/${installation.id}/download`}
                            >
                                Download (3 months)
                            </Button>
                        </div>
                    </CardContainer>


                </Grid>
                <AdminOnly>
                    <Grid item xs={12}>
                        <CardContainer>
                            <DateInput floatPicker
                                       label='Start Date'
                                       placeholder='Enter start date'
                                       autoComplete='off'
                                       displayFormat='ddd, D MMM YYYY'
                                       isOutsideRange={x => false}
                                       isDayHighlighted={isToday}
                                       type='text'
                                       value={reportStartDate}
                                       onChange={setReportStartDate}
                            />

                            <DateInput floatPicker
                                       label='End Date'
                                       placeholder='Enter end date'
                                       autoComplete='off'
                                       displayFormat='ddd, D MMM YYYY'
                                       isOutsideRange={x => false}
                                       isDayHighlighted={(x) => isToday(x, installation.timeZoneName)}
                                       type='text'
                                       value={reportEndDate}
                                       onChange={setReportEndDate}
                            />
                            <div style={{ display: 'flex', gap: '16px' }}>
                                <Button
                                  spaceAbove="small"
                                  target={"_blank"}
                                  href={`${DRIVO_URL}/legacy-report/${installation.id}/download?fromDate=${reportStartDate}&toDate=${reportEndDate}`}
                                >
                                    Download Legacy Report
                                </Button>
                                <Button
                                  spaceAbove="small"
                                  space
                                  target={"_blank"}
                                  href={`${DRIVO_URL}/formatted-buzzout-report/${installation.id}/download?fromDate=${reportStartDate}&toDate=${reportEndDate}`}
                                >
                                    Download Formatted BuzzOuts Report
                                </Button>
                            </div>
                        </CardContainer>
                    </Grid>
                </AdminOnly>

                {/*    For daily report */
                }

                <CanSeeDailyReport>
                    <Grid item xs={6}>
                        <Card className={classes.card}
                            style={{ display: 'flex' }}>
                            <CardContent>
                                <Typography variant="subtitle2"
                                    style={{ fontSize: '30px', textAlign: 'left', fontWeight: 700 }}>
                                    Daily Report
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{ paddingBottom: '40px' }}>
                                        <SingleDatePicker
                                            date={moment(date)}
                                            format={'YY-MM-DD'}
                                            onDateChange={(date) => {
                                                setDate(date.format('YYYY-MM-DD'))
                                            }}
                                            isOutsideRange={() => false}
                                            numberOfMonths={1}
                                            focused={focused}
                                            onFocusChange={({ focused }) => setFocused(focused)}
                                        />
                                    </Grid>

                                    <DailyReportTable
                                        leftSide={"Total Buzzouts"}
                                        rightSide={`${dailyAnalytics?.buzzOutByDate?.count.toLocaleString() ? dailyAnalytics?.buzzOutByDate?.count.toLocaleString() : ''} Buzzout(s)`}
                                    />


                                    <DailyReportTable
                                        leftSide={"Buzzouts Revenue Loss"}
                                        rightSide={`$${dailyAnalytics?.buzzOutByDate?.amount != null ? dailyAnalytics?.buzzOutByDate?.amount.toLocaleString() : ''}`}
                                    />

                                    <DailyReportTable
                                        leftSide={"Total Buzz-ins"}
                                        rightSide={`${dailyAnalytics?.buzzInByDate?.count.toLocaleString() ? dailyAnalytics?.buzzInByDate?.count.toLocaleString() : ''} Buzzin(s)`}
                                    />


                                    <DailyReportTable
                                        leftSide={"Refunds"}
                                        rightSide={`$${dailyAnalytics?.refundByDate?.amount.toLocaleString() ? dailyAnalytics?.refundByDate?.amount.toLocaleString() : ''} from ${dailyAnalytics?.refundByDate?.count ? dailyAnalytics?.refundByDate?.count : ''} refund(s)`}
                                    />

                                    <DailyReportTable
                                        leftSide={"Revenue"}
                                        rightSide={`$${dailyAnalytics?.revenueByDate?.amount.toLocaleString() ? dailyAnalytics?.revenueByDate?.amount.toLocaleString() : ''} from ${dailyAnalytics?.revenueByDate?.count ? dailyAnalytics?.revenueByDate?.count : ''} booking(s)`}
                                    />

                                    <DailyReportTable
                                        leftSide={"Chargebacks"}
                                        rightSide={`${dailyAnalytics?.chargebacks?.count != null ? dailyAnalytics?.chargebacks?.count : ''} booking(s)`}
                                    />

                                    {DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: installation.timeZoneName }).hasSame(DateTime.now().setZone(installation.timeZoneName), 'day') &&
                                        <>
                                            <DailyReportTable
                                                leftSide={"Forecast (Tomorrow)"}
                                                rightSide={`$${dailyAnalytics?.getForeCast?.amount.toLocaleString() ? dailyAnalytics?.getForeCast?.amount.toLocaleString() : ''} from ${dailyAnalytics?.getForeCast?.count ? dailyAnalytics?.getForeCast?.count : ''} booking(s)
`}
                                            />

                                            <DailyReportTable
                                                leftSide={"Revenue this week"}
                                                rightSide={`$${dailyAnalytics?.revenueLastWeek?.amount.toLocaleString() ? dailyAnalytics?.revenueLastWeek?.amount.toLocaleString() : ''} from ${dailyAnalytics?.revenueLastWeek?.count ? dailyAnalytics?.revenueLastWeek?.count : ''} booking(s)`}
                                            />

                                            <DailyReportTable
                                                leftSide={"Revenue this month"}
                                                rightSide={`$${dailyAnalytics?.revenueLastMonth?.amount.toLocaleString() ? dailyAnalytics?.revenueLastMonth?.amount.toLocaleString() : ''} from ${dailyAnalytics?.revenueLastMonth?.count ? dailyAnalytics?.revenueLastMonth?.count : ''} booking(s)`}
                                            />
                                        </>
                                    }

                                    <CardContent>
                                        <ReactApexChart options={dailyRateCardGraphData.options}
                                            series={dailyRateCardGraphData.series} type="pie"
                                            width={500} />
                                    </CardContent>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/*Daily report ends here*/}


                </CanSeeDailyReport>
                <Grid item xs={6}>
                    <BookingSummary installation={installation} />
                </Grid>


            </Grid>
        </div>
    )
}

export default withInstallation(Dashboard)
